import { Chart, registerables } from 'chart.js';

if ($('.js-company-article-sales-table').length > 0) {
    $(document).on('click', '.js-filter-reset', function (e) {
        if ($(".js-company-article-sales-target").val()) {
            e.preventDefault();
            sendAjaxRequestForTable($(".js-company-article-sales-target").val(), []);
        }

    });

    $(document).on('submit', 'form.js-company-articles-sales-filter', function (e) {
        e.preventDefault();
        if ($(".js-company-article-sales-target").val()) {

            let filter = getFilter();

            sendAjaxRequestForTable($(".js-company-article-sales-target").val(), filter)
        }
    });

    $(".js-company-article-sales-btn").on("click", function () {

        let filter = getFilter();
        sendAjaxRequestForTable($(this).data("target"), filter);
    });
}

if ($('#js-revenue-line-chart').length > 0) {
    Chart.register(...registerables);
    const ctx = document.getElementById('js-revenue-line-chart');
    $.ajax($('#portal_revenue_chart_data_ajax-request_get').val(), {
        type: 'GET',
        success: function (result) {
            $('.js-chart-success').hide();
            buildChart(result);

        },
        error: function (result) {
            $('.js-chart-success').hide();
            $('.js-chart-error').show();
        },
    });
}

$(window).on('load', function () {
    if ($('.js-start-type').length > 0) {
        let type = $('.js-start-type').val();
        let filter = getFilter();
        sendAjaxRequestForTable(type, filter)
    }
});
function buildTable(result, target) {
    let colStart =  Number($('.js-col-start').val());
    let currencySymbol = '';

    if(target == "article_group_revenue" || target == "article_revenue"){
        currencySymbol = ' '+ $('.js-currency-symbol').val();
    }

    $(".js-company-article-sales-table").empty();
    $(".js-company-article-sales-table").append(result);
    $(".js-datatable-company-articles-sales").DataTable({
        order: [],
        info: false,
        paging: false,
        searching: true,
        initComplete: function () {
           let serachBar = $('input[type=search].form-control');
           serachBar.removeClass('form-control');
           serachBar.removeClass('form-control-sm');
           serachBar.addClass('custom-search-bar');
        },
        columnDefs: [
            {
                targets: colStart,
                render: $.fn.dataTable.render.number('.', ',', 2, '', currencySymbol)
            },
            {
                targets: colStart + 1,
                render: $.fn.dataTable.render.number('.', ',', 2, '', currencySymbol)
            },
        ],
        language: {
            sEmptyTable: $(".js-no-data-label").val(),
            searchPlaceholder: $(".js-serach-label").val() + "...",
            sSearch: $(".js-serach-label").val() + ":",
            zeroRecords: $(".js-no-records-found").val()
        },
        footerCallback: function (row, data, start, end, display) {
            let api = this.api();

            // Remove the formatting to get integer data for summation
            let intVal = function (i) {
                return typeof i === 'string'
                    ? i.replace(/[\$,]/g, '') * 1
                    : typeof i === 'number'
                    ? i
                    : 0;
            };


            let totalCurrentYear = api
                .column(colStart, { page: 'current' })
                .data()
                .reduce((a, b) => intVal(a) + intVal(b), 0);



            let totalLastYear = api
                .column(colStart + 1, { page: 'current' })
                .data()
                .reduce((a, b) => intVal(a) + intVal(b), 0);

                new Intl.NumberFormat("de-DE", { maximumSignificantDigits: 2 }).format(
                    totalCurrentYear,
                ),

            api.column(colStart).footer().innerHTML = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2}).format(
                totalCurrentYear,
            ) + currencySymbol;
            api.column(colStart + 1).footer().innerHTML = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2}).format(
                totalLastYear,
            ) + currencySymbol;
        }
    });
    $(".js-company-article-sales-btn").removeClass("disabled");
    $(".js-company-article-sales-btn").prop("disabled", false);
}
function disabledAjaxRequestsBtn() {
    $(".js-company-article-sales-btn").addClass("disabled");
    $(".js-company-article-sales-btn").prop("disabled", true);
    $(".js-filter-serach").addClass("disabled");
    $(".js-filter-serach").prop("disabled", true);
    $(".js-filter-reset").addClass("disabled");
    $(".js-filter-reset").prop("disabled", true);
}
function enabledAjaxRequestsBtn() {
    $(".js-company-article-sales-btn").removeClass("disabled");
    $(".js-company-article-sales-btn").prop("disabled", false);
    $(".js-filter-serach").removeClass("disabled");
    $(".js-filter-serach").prop("disabled", false);
    $(".js-filter-reset").removeClass("disabled");
    $(".js-filter-reset").prop("disabled", false);
}

function sendAjaxRequestForTable(target, filter) {
    disabledAjaxRequestsBtn();

    $.ajax($('#portal_revenue_article_sales_table_ajax-request_post').val(), {
        type: "get",
        data: {
            target: target,
            filter: JSON.stringify(filter)
        },
        success: function (result) {
            buildTable(result, target);
            enabledAjaxRequestsBtn();
            $('.select2').select2({
                closeOnSelect: false,
            });
        },
        error: function (result) {
            enabledAjaxRequestsBtn();
        }
    })
}

function buildChart(result) {
    const ctx = document.getElementById('js-revenue-line-chart');
    let data = {
        labels: result.labels,
        datasets: result.datasets
    };
    let options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value, index, ticks) {
                        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0 }).format(value) + " " + result.unit;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.dataset.label + " " + new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(tooltipItem.raw) + " " + result.unit;
                    },
                }
            }
        }
    }
    new Chart(ctx, {
        type: 'line',
        data: data,
        options: options,
    });
}

function getFilter() {
    let filter = {};
    $('.js-filter').each(function (i, obj) {
        obj = $(obj);
        let name = obj.attr('name');
        filter[name] = [];

        $.each(obj.val(), function (index, value) {
            filter[name].push(value);
        });
    })

    return filter;
}

