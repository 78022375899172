import Cookies from 'js-cookie';

const consignmentStore = {
    init: function() {
        consignmentStore.initStockChange();
        consignmentStore.initMinimumStocks();
        consignmentStore.initReorder();
        consignmentStore.initStockUpdateNotice();

        
    },
    initStockUpdateNotice: function() {
        $(document).on('click', '#consignment_stock_update_notice_close', function(e) {
            e.preventDefault();
            $('#consignment_stock_update_notice').addClass("d-none");
            Cookies.set('hide_consignment_stock_update_notice', new Date());
        });

        if($('#consignment_stock_update_notice').length > 0) {
            let keyDate = 25;
            if(Cookies.get('hide_consignment_stock_update_notice')) {
                var today = new Date();
                var lastDate = new Date(Cookies.get('hide_consignment_stock_update_notice'));
                var last25 = new Date();
                if(today.getDate() < keyDate) {
                    last25.setDate(keyDate);
                    last25.setMonth(today.getMonth()-1);
                } else {
                    last25.setDate(keyDate);
                }
                //Jeden 25. im Monat oder wenn lastDate mehr als einen Monat her ist.
                if(new Date().getDate() == keyDate || last25 - lastDate.getTime() >= 2629800*1000) {
                    Cookies.remove("hide_consignment_stock_update_notice");
                    $('#consignment_stock_update_notice').removeClass("d-none");
                }
            } else {
                $('#consignment_stock_update_notice').removeClass("d-none");
            }
        }
    },
    initReorder: function() {
        $(document).on('click', '.consignmentstore_submit_reorder', function(e) {
            e.preventDefault();
            var ids = [];
            var qtys = [];

            $('.maincontent')
                .find('td input[name="reorder"]')
                .each(function() {
                    if($(this).val() <= 0 || $(this).val() == "") {
                        return true;
                    }
                    var id = $(this).data('id');

                    if (id) {
                        ids.push(id);
                        qtys.push($(this).val());
                    }
                });

            if (ids.length == 0) {
                alert($('#i18_no_selection').val());
                return;
            }

            $.ajax($('#consignmentstore_reorder_form_url').val(), {
                method: 'POST',
                data: {
                    _token: $('#_token').val(),
                    ids: ids.join(','),
                    qtys: qtys.join(','),
                },
                success: function(data) {
                    $('#ajaxModal').html(data);
                    $('#ajaxModal').modal('show');
                    consignmentStore.initUpload();
                },
            });
        });
        $(document).on('click', '.consignmentstore_reorder_submit', function(e) {
            var self = $(this);
            e.preventDefault();

            if ($('#consignmentstore_reorder_form').valid()) {
                let data = $('#consignmentstore_reorder_form').serializeArray();
                data.push({
                    name: '_token',
                    value: $('#_token').val(),
                });

                $.post($('#consignmentstore_reorder_submit_url').val(), data, function(resp) {
                    $('#ajaxModal').modal('hide');

                    setTimeout(function() {
                        $('.alert')
                            .hide()
                            .html($('#i18n_offers_ajax_inquery_success').val())
                            .show();
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200); 
                    }, 500);
                });
                return;
            }
        });
    },
    initMinimumStocks: function() {
        $(document).on('click', '.consignmentstore_submit_minstocks', function(e) {
            e.preventDefault();
            var ids = [];
            var notices = [];
            var minStocks = [];

            $('.maincontent')
                .find('td input[name="min_stock"]')
                .each(function() {
                    var id = $(this).data('id');

                    if (id) {
                        ids.push(id);
                        var notice = $("input[name='notice'][data-id='"+id+"']").val();
                        notices.push(notice);
                        var minStock = $("input[name='min_stock'][data-id='"+id+"']").val();
                        minStocks.push(minStock);
                    }
                });

            if (ids.length == 0) {
                alert($('#i18_no_selection').val());
                return;
            }

            $.ajax($('#consignmentstore_minstock_submit_url').val(), {
                method: 'POST',
                data: {
                    _token: $('#_token').val(),
                    ids: ids.join(','),
                    notices: notices.join(','),
                    minStocks: minStocks.join(','),
                },
                success: function(data) {
                    setTimeout(function() {
                        $('.alert')
                            .hide()
                            .html($('#i18n_consignmentstore_ajax_minstock_success').val())
                            .show();
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200); 
                    }, 500);
                },
            });
        });
    },
    initStockChange: function() {
        consignmentStore.stockChangeTableLogic();

        $(document).on('click', '.consignmentstore_stockchange', function(e) {
            e.preventDefault();
            var ids = [];
            var qtys = [];
            var stockChangeOverCurrentStock = [];

            $('.maincontent')
                .find('td input[type=checkbox]:not(:checked)')
                .each(function() {
                    var id = $(this)
                        .closest('tr')
                        .data('id');

                    if (id) {
                        ids.push(id);
                        var qty = $("input[name='current_stock'][data-id='"+id+"']").val()*1;
                        var totalQty = $("input[name='current_stock'][data-id='"+id+"']").attr("data-total-stock")*1;
                        if(qty > totalQty) {
                            stockChangeOverCurrentStock.push(id);
                        }
                        qtys.push(qty);
                    }
                });

            if (ids.length == 0) {
                alert($('#i18_no_selection').val());
                return;
            }
            if(stockChangeOverCurrentStock.length > 0) {
                if(!confirm($('#i18_change_over_quantity').val())) {
                    jQuery.each(stockChangeOverCurrentStock, function(k, id) {
                        $("input[name='consignmentstore_selection_"+id+"']").prop("checked", true).change();
                    });
                    return;
                }
            }

            $.ajax($('#consignmentstore_stockchange_url').val(), {
                method: 'POST',
                data: {
                    _token: $('#_token').val(),
                    ids: ids.join(','),
                    qtys: qtys.join(','),
                },
                success: function(data) {
                    $('#ajaxModal').html(data);
                    $('#ajaxModal').modal('show');
                    consignmentStore.initUpload();
                },
            });
        });

        $(document).on('click', '.consignmentstore_stockchange_submit', function(e) {
            var self = $(this);
            e.preventDefault();

            if ($('#consignmentstore_stockchange_form').valid()) {
                let data = $('#consignmentstore_stockchange_form').serializeArray();
                data.push({
                    name: '_token',
                    value: $('#_token').val(),
                });

                $.post($('#consignmentstore_stockchange_submit_url').val(), data, function(resp) {
                    $('#ajaxModal').modal('hide');

                    setTimeout(function() {
                        let belowMinStockText = "";
                        if(resp.belowMinStock.length > 0) {
                            belowMinStockText = "<br /><br />";
                            $.each(resp.belowMinStock, function(k, v) {
                                belowMinStockText += v.message+"<br />";
                            });
                        }
                        $('.alert')
                            .hide()
                            .html($('#i18n_consignmentstore_ajax_stockchange_success').val()+belowMinStockText)
                            .show();
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200); 
                        window.setTimeout(function() {
                            window.location.reload(true);
                        }, 2500);
                    }, 500);
                }).fail(function(resp) {
                    alert( resp.responseJSON.error );
                });
                return;
            }
        });
    },

    stockChangeTableLogic: function() {
        $(".stockchange-table input[name='current_stock']").each(function(k, v) {
            $(v).on('keyup change', function() {
                if($(v).val() == $(v).attr("data-total-stock")) {
                    $(".stockchange-table input[name='consignmentstore_selection_"+$(v).attr("data-id")+"']").prop("checked", true);
                } else {
                    $(".stockchange-table input[name='consignmentstore_selection_"+$(v).attr("data-id")+"']").prop("checked", false);
                }

                $("tr[data-id='"+$(v).attr("data-id")+"']").find(".total-stock-modal").text($(v).val());
            });
            $("tr[data-id='"+$(v).attr("data-id")+"']").find(".total-stock-modal").text($(v).val());

            $(".stockchange-table input[name='consignmentstore_selection_"+$(v).attr("data-id")+"']").on("change", function(event) {
                let quantity = $(".stockchange-table input[name='current_stock'][data-id='"+$(this).attr("data-id")+"']");
                if($(this).is(":checked")) {
                    quantity.val($(v).attr("data-total-stock"));
                }
            });
        });
    },

    initUpload: function() {
        if ($('.consignmentstore.dropzone').length > 0) {
            $('.consignmentstore.dropzone').dropzone({
                url: $('#consignmentstore_ajax_upload_url').val(),
                maxFilesize: $('#_max_filesize').val(),
                paramName: 'files',
                parallelUploads: 5,
                maxFiles: 5,
                createImageThumbnails: false,
                //acceptedFiles: 'image/*,application/pdf',
                addRemoveLinks: true,
                dictDefaultMessage: $('#i18_dropzone_dictDefaultMessage').val(),
                dictFallbackText: $('#i18_dropzone_dictFallbackText').val(),
                dictFallbackMessage: $('#i18_dropzone_dictFallbackMessage').val(),
                dictFileTooBig: $('#i18_dropzone_dictFileTooBig').val(),
                dictInvalidFileType: $('#i18_dropzone_dictInvalidFileType').val(),
                dictResponseError: $('#i18_dropzone_dictResponseError').val(),
                dictCancelUpload: $('#i18_dropzone_dictCancelUpload').val(),
                dictUploadCanceled: $('#i18_dropzone_dictUploadCanceled').val(),
                dictCancelUploadConfirmation: $('#i18_dropzone_dictCancelUploadConfirmation').val(),
                dictRemoveFile: $('#i18_dropzone_dictRemoveFile').val(),
                dictRemoveFileConfirmation: $('#i18_dropzone_dictRemoveFileConfirmation').val(),
                dictMaxFilesExceeded: $('#i18_dropzone_dictMaxFilesExceeded').val(),
                init: function() {
                    this.on('sending', function(e, xhr, formData) {
                        formData.append('_token', $('#_token').val());
                        formData.append('_upload_identifier', $('#upload_identifier').val());
                    });
                },
            });
        }
    }
};

$(document).ready(function() {
    consignmentStore.init();
    $('.stockchange-table').DataTable().on( 'draw', function () {
        consignmentStore.stockChangeTableLogic();
    });
});