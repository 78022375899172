/**
 * version 2
 * @release development
 */

import Cookies from 'js-cookie';

//eprivacy modal
// $ = jQuery;
function ePrivacy() {
    this.config = false;
    this.modal = false;

    /**
     * Function to create styles on a HTML dom element
     * @return {Boolean} success
     * @private
     * @method
     */

    this.onDocumentReady = function () {
        $('body').addClass('eprivacy-plugin');

        if (window.ePrivacy.checkUrl() == false) {
            if (window.ePrivacy.hasCookies() == false) {
                //window.ePrivacy.showConsent();
                window.ePrivacy.show();
            } else if (window.ePrivacy.config.onSubmission) {
                window.ePrivacy.config.onSubmission(window.ePrivacy.getCookie());
                window.ePrivacy.toggleShow();
                //window.ePrivacy.eprivacySetting();
            }
        }

        $(document).on('click', '.epp-button.epp-secondary', window.ePrivacy.saveCookies);
        $(document).on('click', '.epp-button.epp-primary', window.ePrivacy.saveAllCookies);

        $(document).on('click', '#ePrivacy_onCookieSetting', _onCookieButtonClick);

        $(document).on('click', '.epp-open-settings', function () {
            //animate the togglebutton
            window.ePrivacy.show();
            var direction = window.ePrivacy.config.toggleAlign;

            if (!(direction == 'right' || direction == 'left')) {
                direction = 'right';
            }

            $('.epp-cookie').animate({ left: '-100px' }, 200, function () {
                $('.epp-cookie').remove();
            });
        });
    };

    /**
     * Holt sich die deactivatedSites & überprüft ob es sich bei der Seite um einer dieser Links handel.
     * Wenn nein dann Cookie show wenn Ja dann kein Cookie show
     */
    this.checkUrl = function (check) {
        check = false;
        var deactivatedSites = window.ePrivacy.config.deactivatedSites;
        var url = window.location.href;
        $.each(deactivatedSites, function (key, value) {
            if (value == url) {
                check = true;
            }
        });
        return check;
    };

    /*
     * Bei click auf einstellungen im toggleShow,
     * animiert & remove toggleShow. Öffnet show
     */
    const _onCookieButtonClick = function () {
        $('.epp-cookie-text')
            .addClass('epp-blur')
            .delay(1000)
            .queue(function (next) {
                $(this).removeClass('epp-blur');
                next();
            });
        $('.epp-cookie .epp-cookie-wrapper').delay(400).animate({ width: 'toggle' }, 400);

        var direction = window.ePrivacy.config.toggleAlign;

        if (!(direction == 'right' || direction == 'left')) {
            direction = 'right';
        }

        var options = {};
        options[direction] = '-100px';

        _cookieButtonToggleShow(options);
    };

    const _cookieButtonToggleShow = function (direction) {
        $('.epp-cookie')
            .delay(1400)
            .animate(direction, 500, function () {
                window.ePrivacy.show();
                $('.epp-cookie').remove();
            });
    };

    /*
     * Setzt den geklickten Button auf disabled. Animiert den Modalwrapper
     * und löscht die Klasse "eprivay-plugin" vom body
     * */
    this._submitButtonEffect = function () {
        $('.epp-modal-buttons').parent().find('button').attr('disabled', 'disabled');
        $('.epp-modal .epp-modal-wrapper')
            .delay(500)
            .animate({ top: '-100%' })
            .queue(function (next) {
                window.ePrivacy.modal.hide();
                $('body').removeClass('epp-overflow').children().removeClass('epp-wrapper-blur');
                $('body .epp-modal').remove();
                next();
            });
    };

    this.showConsent = function () {
        var firstTime = 0;

        if (Cookies.get('epp-cookie') === undefined) {
            firstTime = 1;
        }

        var $desc = this.config.lang.COOKIE_DESCRIPTION;
        var $cookieExpireDay = this.config.lang.COOKIE_DETAILSHOURS;

        $('.eprivacy-plugin').append('<div class="epp-consent"></div>');

        this.consent = $('body .epp-consent');
        this.consent.append('<div class="epp-consent-wrapper" data-firstTime=' + firstTime + '></div>').append('<div class="epp-consent-content"></div>');

        this.consent.content = $('body .epp-consent-content');
        this.consent.content
            .append('<div class="epp-consent-disclaimer"><p>' + $desc + '</p></div>')
            .append('<div class="epp-consent-button"><button class="epp-button epp-btn-consent">Akzeptieren</button><button class="epp-button epp-settings"><i class="fas fa-cog"></i></button></div>');

        $('.epp-button.epp-btn-consent').on('click', function () {
            window.ePrivacy.animateConsentWrapper();
            window.ePrivacy.getAllLevels();
        });
        $('.epp-button.epp-settings').on('click', function () {
            window.ePrivacy.animateConsentWrapper();
            window.ePrivacy.show();
        });
    };

    /**
     * Holt alle levels, speichert diese in ein Array und übergibt dieses an saveCookies
     * */
    this.getAllLevels = function () {
        var acceptedLevels = [];
        $.each(this.config.levels, function () {
            var cookieName = this.name;
            acceptedLevels.push(cookieName);
        });
        window.ePrivacy.saveCookies(acceptedLevels, true);
    };
    /**
     * Holt alle levels, speichert diese in ein Array und übergibt dieses an saveCookies
     * */
    this.animateConsentWrapper = function () {
        $('.epp-consent').delay(400).animate({ bottom: '-300px' }, 400);
        setTimeout(function () {
            $('.epp-consent').remove();
        }, 1000);
    };

    /*
     * Erstellt die Html struktur & öffnet das Modal
     * mit allen Informationen.
     * */
    this.show = function () {
        var firstTime = 0;

        if (Cookies.get('epp-cookie') === undefined) {
            firstTime = 1;
        }

        var $headline = this.config.lang.COOKIE_HEADLINE;
        var $desc = this.config.lang.COOKIE_DESCRIPTION;
        var $btnSaveAll = this.config.lang.COOKIE_BTNSAVEALL;
        var $btnSave = this.config.lang.COOKIE_BTNSAVE;

        var $impressText = this.config.lang.COOKIE_IMPRESS;
        var $dataPrivacyText = this.config.lang.COOKIE_DATAPRIVACY;

        var $cookieDetails = this.config.lang.COOKIE_DETAILS;
        var $cookieDetailsActive = this.config.lang.COOKIE_DETAILSACTIVE;

        var $cookieName = this.config.lang.COOKIE_DETAILSNAME;
        var $cookiePublisher = this.config.lang.COOKIE_DETAILSPUBLISHER;
        var $cookiePurpose = this.config.lang.COOKIE_DETAILSPURPOSE;
        var $cookieExpire = this.config.lang.COOKIE_DETAILSEXPIRE;
        var $cookieExpireDay = this.config.lang.COOKIE_DETAILSHOURS;

        var $impress = '<a href="' + this.config.impressLink + '" target="_blank" class="epp-info-impress">' + $impressText + '</a>';
        var $dataPrivacyLink = '<a href="' + this.config.dataPrivacyLink + '" target="_blank" class="epp-info-data-privacy">' + $dataPrivacyText + '</a>';

        $('.eprivacy-plugin').append('<div class="epp-modal"></div>');
        //wenn div#wrapper vorhanden hängt class für blur an
        $('body').find('div#wrapper').toggleClass('epp-wrapper-blur');
        $('body').toggleClass('epp-overflow');

        this.modal = $('body .epp-modal');
        this.modal.append('<div class="epp-modal-wrapper" data-firstTime=' + firstTime + '></div>').append('<div class="epp-modal-wrapper-bg"></div>');
        this.modal
            .find('.epp-modal-wrapper')
            .append('<div class="epp-modal-header"><h3>' + $headline + '</h3></div>')
            .append('<div class="epp-modal-description"><p>' + $desc + '</p></div>')
            //hier sollen alle levels +  namen angezeigt werden, als checkbox
            .append('<div class="epp-modal-content"></div>')
            //buttons für alle akzeptieren bzw speichern
            .append('<div class="epp-modal-buttons"><button class="epp-button epp-primary">' + $btnSaveAll + '</button><button class="epp-button epp-secondary">' + $btnSave + '</button></div>')
            //impressum - Datenschutz - Cookie-Detail
            .append('<div class="epp-modal-info">' + $impress + '' + $dataPrivacyLink + '</div>');

        $.each(this.config.levels, function () {
            var $hiddenlevelName = this.hiddenName;
            var $levelName = this.name;
            var $levelDesc = this.descriptions;
            var $levelAlways = this.always;

            var $cookies = this.cookieDetail;

            if ($levelAlways == true) {
                $levelAlways = 'checked disabled';
            } else {
                $levelAlways = '';
            }

            $('.epp-modal-content')
                .append(
                    '<div class="epp-content-checkbox"><label for="checkbox-' +
                        $levelName +
                        '" class="epp-content-label"><input type="checkbox" levelName="' +
                        $hiddenlevelName +
                        '" name="' +
                        $levelName +
                        '" id="checkbox-' +
                        $levelName +
                        '" ' +
                        $levelAlways +
                        '><span class="epp-checkbox-checkmark"></span></label><div class="epp-content-toggle"><p>' +
                        $levelName +
                        '</p><div id="epp-toggle-' +
                        $levelName +
                        '" class="epp-cookie-desc-toggle"></div></div></div>'
                )
                .append('<div id="epp-cookie-desc-' + $levelName + '" class="epp-content-cookie-desc" style="display:none; opacity: 0;"><p>' + $levelDesc + '</p></div>');
            $('#epp-cookie-desc-' + $levelName).append(
                '<div id="epp-cookie-details-' +
                    $levelName +
                    '" class="epp-cookie-details"><span class="epp-detail-titel">' +
                    $cookieDetails +
                    '</span><div class="epp-cookie-detail-content"></div></div>'
            );

            /*
             * Öffnet die Cookiedetails und
             * ändern den Text im Button
             * */
            $('#epp-cookie-details-' + $levelName + ' .epp-detail-titel').click(function () {
                // erstellt ein Modal im modal welches dann die einzelnen cookies mit beschreibung etc anzeigt
                // erstellt dann auch ein x icon mit dem man das modal wieder verlassen kann.

                $('.epp-cookie-content').slideUp();
                var target = $(this).parent().next('.epp-content-cookie-desc');
                target.slideToggle().toggleClass('epp-show');
                $('.epp-content-cookie-desc').not(target).slideUp().removeClass('epp-show'); // hide other open elements
                $('.epp-modal').click(function (event) {
                    if (target.has(event.target).length == 0) {
                        target.slideUp().removeClass('epp-show');
                        target.find('.epp-cookie-content').slideUp();
                        $('.epp-cookie-desc-toggle').removeClass('epp-toggle-up');
                    }
                });
                event.stopPropagation();

                $('.epp-modal-wrapper').append('<div class="cookie-desc-content test-' + $levelName + '"></div>');
                $('.test-' + $levelName + '').append('<span class="cookie-desc-exit"></span>');
                $('.test-' + $levelName + '').append('<p class="cookie-desc-lvlname">' + $levelName + '</p>');
                $.each($cookies, function () {
                    $('.test-' + $levelName + '').append(
                        '<div class="epp-cookie-content show-' +
                            $levelName +
                            '"><span>' +
                            $cookieName +
                            '</span><p>' +
                            this.name +
                            '</p><span>' +
                            $cookiePublisher +
                            '</span><p>' +
                            this.publisher +
                            '</p><span>' +
                            $cookiePurpose +
                            '</span><p>' +
                            this.purpose +
                            '</p><span>' +
                            $cookieExpire +
                            '</span><p>' +
                            this.expire +
                            '</p></div>'
                    );
                });

                $('.cookie-desc-exit').on('click', function () {
                    $('.cookie-desc-exit').remove();
                    $('.test-' + $levelName + '').remove();
                });

                var btn = $(this);
                if (btn.html() === $cookieDetailsActive) {
                    btn.html($cookieDetails);
                } else {
                    btn.html($cookieDetailsActive);
                }

                //$('body').find('.test-'+ $levelName +'' ).slideToggle();
            });
        });

        /*
         * Öffnet die cookiebeschreibung
         * */
        $('.epp-content-toggle').click(function (event) {
            $('.epp-cookie-content').slideUp();
            var target = $(this).parent().next('.epp-content-cookie-desc');
            target.slideToggle().toggleClass('epp-show');
            $('.epp-content-cookie-desc').not(target).slideUp().removeClass('epp-show'); // hide other open elements
            $('.epp-modal').click(function (event) {
                if (target.has(event.target).length == 0) {
                    target.slideUp().removeClass('epp-show');
                    target.find('.epp-cookie-content').slideUp();
                    $('.epp-cookie-desc-toggle').removeClass('epp-toggle-up');
                }
            });
            event.stopPropagation();
        });

        window.ePrivacy._setCheckedCheckboxes();
    };

    /*
     * Erstellt den Toggler für die fucntion show.
     */
    this.toggleShow = function () {
        if (window.ePrivacy.config.toggle != 0) {
            var $changeText = this.config.lang.COOKIE_SETTINGS;
            var $alignToggle = 'right';

            var customPadding = parseInt(window.ePrivacy.config.customPadding);
            var customPaddingBottom = parseInt(window.ePrivacy.config.customPaddingBottom);

            $('.eprivacy-plugin').append('<div class="epp-cookie"></div>');
            var toggleShow = $('body .epp-cookie');

            $('body .epp-cookie').css({ bottom: customPaddingBottom });

            if (this.config.toggleAlign === 'left') {
                toggleShow.addClass('epp-cookie-left');
                $('body .epp-cookie')
                    .delay(1400)
                    .animate({ left: '' + customPadding + '' }, 500);
            } else {
                $('body .epp-cookie')
                    .delay(1400)
                    .animate({ right: '' + customPadding + '' }, 500);
            }

            toggleShow.append('<div class="epp-cookie-icon"></div>');
            toggleShow.append(
                '<div class="epp-cookie-wrapper"><div class="epp-cookie-text"><p>' +
                    $changeText +
                    '</p></div><div class="epp-btn-group"><button id="ePrivacy_onCookieSetting" class="epp-cookie-button"></button></div></div>'
            );

            var cookieIcon = $('.epp-cookie-icon');
            var cookieWrapper = $('.epp-cookie .epp-cookie-wrapper');
            cookieWrapper.hide();

            cookieIcon.click(window.ePrivacy._slideToggleShow);
        }
    };

    /*
     * Zeigt den Infotext vom toogleShow an. Mit animation
     */
    this._slideToggleShow = function () {
        var cookieIcon = $('.epp-cookie-icon');
        var cookieWrapper = $('.epp-cookie .epp-cookie-wrapper');
        cookieIcon.toggleClass('hovered');
        $('.epp-cookie-text')
            .addClass('epp-blur')
            .delay(1000)
            .queue(function (next) {
                $(this).removeClass('epp-blur');
                next();
            });
        cookieWrapper.delay(400).animate({ width: 'toggle' }, 400);
    };

    this.init = function (options) {
        window.ePrivacy = this;
        this.config = options;

        /**
         * Default language
         */
        var defaultLang = {
            COOKIE_SETTINGS: 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.',
            COOKIE_HEADLINE: 'Wir verwenden Cookies',
            COOKIE_DESCRIPTION: 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.',
            COOKIE_BTNSAVEALL: 'Alle akzeptieren',
            COOKIE_BTNSAVE: 'Speichern',
            COOKIE_IMPRESS: 'Impressum',
            COOKIE_DATAPRIVACY: 'Datenschutzerklärung',
            COOKIE_DETAILS: 'Cookiedetails anzeigen',
            COOKIE_DETAILSACTIVE: 'Cookiedetails ausblenden',
            COOKIE_DETAILSNAME: 'Cookie Name',
            COOKIE_DETAILSPUBLISHER: 'Cookie Anbieter',
            COOKIE_DETAILSPURPOSE: 'Cookie Zweck',
            COOKIE_DETAILSEXPIRE: 'Cookie Laufzeit',
            COOKIE_DETAILSHOURS: 'Tag',
        };

        if (this.config.lang) {
            $.extend(defaultLang, this.config.lang);
        }

        this.config.lang = defaultLang;

        $(document).ready(this.onDocumentReady);
    };

    /*
     * Holt alle checked Checkboxen und speichert diese im Array acceptedLevels.
     * Löscht dann den epp-cookie
     * Speichert dann den epp-cookie mit allen checked Checkboxen als string in values
     * */
    this.saveCookies = function (consent, check) {
        console.log('test');
        /* Sucht alle Checkboxen und speichert diese in ein Array */
        if (check == true) {
            var acceptedLevels = consent;
        } else {
            var acceptedLevelsEl = $('body.eprivacy-plugin .epp-modal-content').find('input:checked');
            acceptedLevels = [];

            $.each(acceptedLevelsEl, function () {
                // cookieName = $(this).attr('name'); @Funktioniert
                var cookieName = $(this).attr('levelname');
                acceptedLevels.push(cookieName);
            });
        }

        /* Löscht dann den epp-cookie */
        var cookies = Cookies.get();
        for (var cookie in cookies) {
            //$.removeCookie(cookie);
            window.ePrivacy.removeCookie(cookie);
        }

        /* Speichert dann den epp-cookie mit allen checked Checkboxen als string in values */
        Cookies.set('epp-cookie', JSON.stringify(acceptedLevels), {
            path: '/',
            expires: 60,
        });

        /*
         * Guckt ob epp-modal-wrapper das erste mal geöffnet ist.
         * Wenn nein läd er die Seite neu
         */
        if ($('body.eprivacy-plugin .epp-modal-wrapper').data('firsttime') == 1 || $('body.eprivacy-plugin .epp-consent-wrapper').data('firsttime') == 1) {
            if (window.ePrivacy.config.onSubmission) {
                window.ePrivacy.config.onSubmission(acceptedLevels);
            }
            window.ePrivacy.toggleShow();
            //window.ePrivacy.eprivacySetting();
        } else {
            if (window.ePrivacy.config.onSubmission) {
                window.ePrivacy.config.onSubmission(acceptedLevels);
            }
            location.reload();
        }

        window.ePrivacy._submitButtonEffect();
    };

    this.saveAllCookies = function () {
        var allCheckboxes = $('body.eprivacy-plugin .epp-modal-content').find('input:checkbox');
        $.each(allCheckboxes, function () {
            $(this).prop('checked', true);
        });
        window.ePrivacy.saveCookies();
    };

    this.removeCookie = function (name) {
        var domain = window.location.hostname.replace('www', '');
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' + domain + '; path=/';
        Cookies.remove(name);
    };

    /**
     * checked ob cookies gesetzt sind. Wenn ja übergibt hasCookies = true, wenn nein
     * übergibt hasCookies = false.
     */
    this.hasCookies = function (hasCookies) {
        var eppCookie = Cookies.get('epp-cookie');
        hasCookies;
        if (eppCookie) {
            hasCookies = true;
        } else {
            hasCookies = false;
        }
        return hasCookies;
    };

    /* Holt den epp-cookie und wandelt Ihn in JSON um.
     * Function gibt den Wert getCookie zurück
     */
    this.getCookie = function (getCookie) {
        if (!window.ePrivacy.hasCookies() == false) {
            getCookie = $.parseJSON(Cookies.get('epp-cookie'));
            return getCookie;
        }
        return 'false';
    };

    /**
     * Sucht alle Checkboxen im Modal und vergleicht diese mit den checked checkboxen
     * aus dem Cookie & setzt die checkboxen dann checked
     */
    this._setCheckedCheckboxes = function () {
        var checkedBoxes = window.ePrivacy.getCookie();
        var allBoxes = $('.epp-modal .epp-modal-content').find('input:checkbox');

        $.each(allBoxes, function () {
            // var name = $(this).attr("name"); @Funktioniert
            var name = $(this).attr('levelname');
            if (checkedBoxes.indexOf(name) != -1) {
                $(this).attr('checked', 'checked');
            }
        });
    };

    /**
     * Sucht nach dem Code [eprivacy-cookie-settings] und ersetzt Ihn dann mit einem Button.
     * Dieser öffnet dann show().
     */
    this.eprivacySetting = function () {
        var eprivacySettingCode = '[eprivacy-cookie-settings]';
        if (window.ePrivacy.config.toggle == false) {
            if ($('p:contains(' + eprivacySettingCode + ')').length > 0) {
                var settings = $('p:contains(' + eprivacySettingCode + ')');
                settings.html('<h2>ePrivavy Einstellungen<h2>').append('<button type="button" class="eprivacy-settings-btn">Cookie Einstellungen ändern</button>');
                $('.eprivacy-settings-btn').on('click', function () {
                    window.ePrivacy.show();
                });
            } else {
                return false;
            }
        }
    };
}

(function () {
    var eP = new ePrivacy();

    eP.init({
        impressLink: '/cms/page/6',
        dataPrivacyLink: '/cms/page/5',
        deactivatedSites: ['/cms/page/5', '/cms/page/6'],
        toggle: true,
        toggleAlign: 'left',
        customPadding: '20',
        customPaddingBottom: '45',
        onSubmission: function (allowedLevels) {},

        lang: {
            COOKIE_SETTINGS: $('#eprivacyTranslation_setting').val(),
            COOKIE_HEADLINE: $('#eprivacyTranslation_headline').val(),
            COOKIE_DESCRIPTION: $('#eprivacyTranslation_desc').val(),
            COOKIE_BTNSAVEALL: $('#eprivacyTranslation_btnSaveAll').val(),
            COOKIE_BTNSAVE: $('#eprivacyTranslation_btnSave').val(),
            COOKIE_IMPRESS: $('#eprivacyTranslation_impressText').val(),
            COOKIE_DATAPRIVACY: $('#eprivacyTranslation_dataPrivacyText').val(),
            COOKIE_DETAILS: $('#eprivacyTranslation_cookieDetails').val(),
            COOKIE_DETAILSACTIVE: $('#eprivacyTranslation_cookieDetailsActive').val(),
            COOKIE_DETAILSNAME: $('#eprivacyTranslation_cookieName').val(),
            COOKIE_DETAILSPUBLISHER: $('#eprivacyTranslation_cookiePublisher').val(),
            COOKIE_DETAILSPURPOSE: $('#eprivacyTranslation_cookiePurpose').val(),
            COOKIE_DETAILSEXPIRE: $('#eprivacyTranslation_cookieExpire').val(),
            COOKIE_DETAILSHOURS: $('#eprivacyTranslation_cookieExpireDay').val(),
        },

        levels: [
            {
                name: $('#eprivacyTranslation_lvl1_name').val(),
                hiddenName: 'essential',
                always: true,
                descriptions: $('#eprivacyTranslation_lvl1_descriptions').val(),
                cookieDetail: [
                    {
                        name: 'epp-cookie',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_purpose').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                    {
                        name: 'laravel_session, XSRF-TOKEN',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_second').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                    {
                        name: 'language',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_third').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                    {
                        name: 'country',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_fourth').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                    {
                        name: 'shopping_cart',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_fifth').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                    {
                        name: 'product_list_state_*',
                        publisher: 'webshop-fapach.pimbase.pro',
                        purpose: $('#eprivacyTranslation_lvl1_first_sixth').val(),
                        expire: $('#eprivacyTranslation_1year').val(),
                    },
                ],
            },
            {
                name: $('#eprivacyTranslation_lvl2_name').val(),
                hiddenName: 'statistics',
                always: false,
                descriptions: $('#eprivacyTranslation_lvl3_descriptions').val(),
                cookieDetail: [
                    {
                        name: '_ga,_gat,_gid,_gat_UA',
                        publisher: 'Google LLC',
                        purpose: $('#eprivacyTranslation_lvl3_first_purpose').val(),
                        expire: $('#eprivacyTranslation_session').val(),
                    },
                ],
            },
        ],
    });
})();
